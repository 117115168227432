import {CompileMarkdown} from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';
import {Props_Paragraph} from './Paragraph.props'

const Paragraph = (props: Props_Paragraph) => {
    const {repeatable_paragraph} = props;
    
    return (
        <section className="paragraph-block">
            <div className="paragraph-block__txt-container">
                {repeatable_paragraph.map((para, idx) => (
                    <div className="paragraph-block__txt" key={idx}>
                        {CompileMarkdown(para.text.value)}
                    </div>
                ))}

            </div>
        </section>
    )
} 

export default Paragraph;