

export const addLogo = () => {
    const jobTiles = document.querySelectorAll(".attrax-vacancy-tile");

    if (!jobTiles) {
        return;
    }

    jobTiles.forEach((jobTile) => {
        let logoWrapper = document.createElement("div");
        logoWrapper.classList.add("attrax-vacancy-tile__logo-wrapper");
        jobTile.prepend(logoWrapper);
    });
};

export const logoReplacer = () => {
    const jobOptionClass: string = ".attrax-vacancy-tile--";

    const optionArray: Array<string> = ["germany", "france", "united-states"];
    //the options given should be the same name give to logos placed in the logo folder

    optionArray.forEach((option) => {
        const className = jobOptionClass + option;

        if (document.getElementsByClassName(className)) {
            const filePath = `url("/Blob/images/logos/${option}.svg")`;

            const foundClass: Element[] = Array.from(
                document.querySelectorAll(className)
            );

            foundClass.forEach((option) => {
                const parentTile: HTMLElement = option.closest(
                    ".attrax-vacancy-tile"
                );
                const logowrapper: HTMLElement = parentTile.querySelector(
                    ".attrax-vacancy-tile__logo-wrapper"
                );
                logowrapper.style.backgroundImage = filePath;
            });
        }
    });
};

export const brandClassAssignment = () => {
    const brandtiles = document.querySelectorAll(
        ".attrax-vacancy-tile__option-brand"
    );

    if (!brandtiles) {
        return;
    }

    brandtiles.forEach((brandtile) => {
        const brandclass = [...brandtile.classList].find((el) =>
            el.includes("attrax-vacancy-tile__option-brand--")
        );

        brandtile.closest(".attrax-vacancy-tile").classList.add(brandclass);
    });
};

export const setActive = () => {
    const jobtiles = document.querySelectorAll(".attrax-vacancy-tile");

    if (!jobtiles) {
        return;
    }

    jobtiles.forEach((jobtile) => {
        jobtile.classList.add("attrax-vacancy-tile--active");
    });
};
