import { useState } from 'react';
import { Props_BarChart } from './BarChart.props';
import { CompileMarkdown } from "@ClientBase/React/ReactSnippetMDCompiler";

const BarChart = (props: Props_BarChart) => {
    const [activeBarIndex, setActiveBarIndex] = useState(0);
    const [isCardVisible, setIsCardVisible] = useState(true);
    const {accessibileHeading, repeatable_bars} = props
    const handleBarClick = (index: number) => {
        setIsCardVisible(false);
        setTimeout(() => {
            setActiveBarIndex(index);
            setIsCardVisible(true);
        }, 500);
    };

    return (
        <section className="bar-chart">
            {accessibileHeading?.text?.value &&
                <h2 className="bar-chart__accessible-header">{accessibileHeading.text.value}</h2>

            }

            <div className="bar-chart__container">
                <div className="bar-chart__bar-section">
                    {repeatable_bars.map((bar, index) => (
                        <div
                            key={index}
                            className={`bar-chart__bar bar-chart__bar--${index + 1} ${
                                activeBarIndex === index ? 'bar-chart__bar--active' : ''
                            }`}
                            onClick={() => handleBarClick(index)}
                        >
                            <div className="bar-chart__heading-section">
                                <h3 className="bar-chart__bar-number">{index + 1}</h3>
                                <p className="bar-chart__bar-title">{bar.barTitle.value}</p>
                            </div>
                            <div className="bar-chart__content-section">
                                <figure className="bar-chart__image-container">
                                    <img
                                        src={bar.barImage.value}
                                        alt={bar.barImageAlt.value || ''}
                                        height="157"
                                        width="157"
                                        className="bar-chart__image"
                                    />
                                </figure>
                                <p className="bar-chart__inner-title">{bar.barInnerTitle.value}</p>
                                <div className="bar-chart__text">
                                    {CompileMarkdown(bar.barContent.value)}
                                </div>
                                <a  
                                href={bar.barLinkHref.value}
                                className="bar-chart__link btn"
                                aria-label={bar.barLinkAriaLabel.value}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div className={`bar-chart__card-section ${
                    isCardVisible ? 'bar-chart__card-section--active' : ''
                }`}>
                    {isCardVisible && (
                        <div className="bar-chart__content-section">
                            <figure className="bar-chart__image-container">
                                <img
                                    src={repeatable_bars[activeBarIndex].barImage.value}
                                    alt={repeatable_bars[activeBarIndex].barImageAlt.value || ''}
                                    height="157"
                                    width="157"
                                    className="bar-chart__image"
                                />
                            </figure>
                            <p className="bar-chart__inner-title">
                                {repeatable_bars[activeBarIndex].barInnerTitle.value}
                            </p>
                            <div className="bar-chart__text">
                                {CompileMarkdown(repeatable_bars[activeBarIndex].barContent.value)}
                            </div>
                            <a
                            href={repeatable_bars[activeBarIndex].barLinkHref.value}
                            className="bar-chart__link btn"
                            aria-label={repeatable_bars[activeBarIndex].barLinkAriaLabel.value}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default BarChart;