﻿

export function upOrDownOnScrollWheel( elm: JQuery): void {

    let lastScrollPos = 0;

    $(window).on('scroll', function () {

        const currentScroll: number = $(this).scrollTop();

        if (currentScroll <= 0 && lastScrollPos < 0) {
            $(elm).removeClass('scroll-up');
            $(elm).removeClass('scroll-down');

        }

        if (currentScroll > lastScrollPos) {
            $(elm).addClass('scroll-down');
            $(elm).removeClass('scroll-up');

        } else {

            $(elm).addClass('scroll-up')
            $(elm).removeClass('scroll-down')
        }

        if (currentScroll === 0) {
            $(elm).removeClass('scroll-up');

        }

        lastScrollPos = currentScroll;

    })

}

//check if element is outside of the viewport

interface Out {
    top: boolean,
    left: boolean,
    bottom: boolean,
    right: boolean,
    any: boolean,
    all: boolean
}

export const isElementOut = (el: HTMLElement) => {

    if (!el) {
        return null
    }

    const bounding: DOMRect | null = el.getBoundingClientRect();

    const out = {} as Out;
    out.top = bounding.top < 0;
    out.left = bounding.left < 0;
    out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
    out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
    out.any = out.top || out.left || out.bottom || out.right;
    out.all = out.top && out.left && out.bottom && out.right;

}