

export function showStickyApply($: JQueryStatic): void {

    if ($('body').hasClass('job-details')) {

        let lastPos = 0;

        const contentHeight = $('.job-details__main-content').height() + 430;

        $(document).on('scroll', function() {
            const currentPos = $(this).scrollTop();

            if ($(window).width() < 991) {
                // if ( currentPos > lastPos && currentPos < contentHeight) {
                if ( currentPos > 260) {
                    $('.sticky-apply').addClass('show');    
                } else {
                    $('.sticky-apply').removeClass('show');    
                }
                lastPos = currentPos;
                
            } else {  
                if (currentPos > 180 && currentPos > lastPos && currentPos < contentHeight) {
                    $('.sticky-apply').addClass('show');    
                } else {
                    $('.sticky-apply').removeClass('show');    
                }
                lastPos = currentPos;
            }
            
            
        })
    }
}