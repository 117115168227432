﻿

export const setHeadingonLoad = () => {
    const snippets: NodeListOf<HTMLElement> = document.querySelectorAll('.tab-switcher');
    snippets?.forEach(snippet => {
        const headingText = snippet.querySelector<HTMLElement>('.tab-switcher__button--active')?.textContent.trim();
        const activeHeading = snippet.querySelector<HTMLElement>('.tab-switcher__active-heading');
        
        if (activeHeading && headingText !== undefined) {
            activeHeading.innerText = headingText;
            activeHeading.classList.add('tab-switcher__active-heading--loaded');
        }

    })

    const onLoadActive = document.querySelector<HTMLElement>('.tab-switcher__button--active')?.offsetHeight;
    const activeEl: HTMLElement = document.querySelector('.tab-switcher__active-indicator');

    if (onLoadActive && activeEl) {
        activeEl.style.height = onLoadActive.toString();
    }
} 

export const ClickHandler = () => {
    const snippets: NodeListOf<HTMLElement> = document.querySelectorAll('.tab-switcher');
    snippets?.forEach(snippet => {
        const headingToggle: HTMLElement | null = snippet.querySelector('.tab-switcher__active-heading');
        const buttons: NodeListOf<HTMLElement> = snippet.querySelectorAll('.tab-switcher__button');
        const infoSections: NodeListOf<HTMLElement> | null = snippet.querySelectorAll('.tab-switcher__info-container')

        if (!headingToggle || !buttons || !infoSections) return;

        //toggle the buttons container for smaller screens
        headingToggle.addEventListener('click', function() {
            const buttonContainer: HTMLElement = this.parentElement.querySelector('.tab-switcher__button-container');
            closeMobileHeadingSection(buttonContainer);
        })

        const buttonPos: number[] = []

        //onClick handler functionality
        buttons.forEach((button, index) => {
            buttonPos.push(getOffset(button).top)
            
            button.addEventListener('click', function() {
                const buttonHeading = button.innerText;
                const listPos = getOffset(snippet.querySelector('.tab-switcher__button-container')).top;
                const pos = ((buttonPos[index] - listPos) - 20).toString();
                const selectionHeight = button.offsetHeight.toString();
                const activeIndicator: HTMLElement = snippet.querySelector('.tab-switcher__active-indicator');

                if (window.innerWidth < 768) {
                    closeMobileHeadingSection(this.parentElement)
                }

                headingToggle.innerText = buttonHeading;

                if (activeIndicator) activeIndicator.style.height = selectionHeight;

                infoSections.forEach((section, idx) => {
                    if (idx === index) {
                        section.classList.add('tab-switcher__info-container--active');
                    } else {
                        section.classList.remove('tab-switcher__info-container--active');
                    }
                })

                for (let sibling of button.parentNode.children) {
                    if (sibling !== button) sibling.classList.remove('tab-switcher__button--active');
                }

                button.classList.add('tab-switcher__button--active');
    
                if (activeIndicator && this !== button.parentElement.children[1]) {
                    activeIndicator.style.top = `${pos}px`;
                } else {
                    activeIndicator.style.top = `${(Number(pos) + 20)}px`;
                    
                }
            })
        })
    })
}

//helper function for click handler
const getOffset = (el: HTMLElement) => {
    if (!el.getClientRects().length)
    {
        return { 
            top: 0,
            left: 0 
        };
    }

    let rect = el.getBoundingClientRect();
    let win = el.ownerDocument.defaultView;

    return (
    {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
    });  
}

//close mobile heading on parent or children
const closeMobileHeadingSection = (el: HTMLElement) => {
    el.classList.toggle('tab-switcher__button-container--open');
    if (el.classList.contains('tab-switcher__button-container--open')) {
        el.style.height = `${el.scrollHeight.toString()}px`;
    } else {
        el.style.height = '0px';
    }
}