import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Props_NewTabSwitcher } from './NewTabSwitcher.props';
import { CompileMarkdown } from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

const NewTabSwitcher: React.FC<Props_NewTabSwitcher> = ({ repeatable_content }) => {
    const [activeTab, setActiveTab] = useState(0);

    const contentVariants = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 }
    };

    return (
        <section className="new-tab-switcher-react">
            <h2 className="new-tab-switcher-react__acessible-header">
                Accessible Heading
            </h2>

            <div className="new-tab-switcher-react__container">
                <div className="new-tab-switcher__heading-section">
                    {repeatable_content.map((content, index) => (
                        <div
                            key={index}
                            className={`new-tab-switcher-react__heading new-tab-switcher-react__heading--${index + 1}`}
                        >
                            <p
                                className={`new-tab-switcher-react__heading-txt ${
                                    activeTab === index ? 'new-tab-switcher-react__heading-txt--active' : ''
                                }`}
                                onClick={() => setActiveTab(index)}
                                role="tab"
                                aria-selected={activeTab === index}
                                tabIndex={0}
                            >
                                {content.sectionButtonText.value}
                            </p>
                        </div>
                    ))}
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        className={`new-tab-switcher-react__body-section ${
                            activeTab !== null ? 'new-tab-switcher-react__body-section--active' : ''
                        }`}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={contentVariants}
                        transition={{
                            duration: 0.3,
                            ease: "easeInOut"
                        }}
                    >
                        <div className="new-tab-switcher-react__content-section">
                            <div className="new-tab-switcher-react__content-inner">
                                <h3 className="new-tab-switcher-react__content-heading">
                                    {repeatable_content[activeTab].sectionHeading.value}
                                </h3>
                                <div className="new-tab-switcher-react__content-text">
                                    {CompileMarkdown(repeatable_content[activeTab].sectionContent.value)}
                                </div>
                                <a
                                    href={repeatable_content[activeTab].sectionLinkHref.value}
                                    className="new-tab-switcher-react__link btn"
                                    aria-label={repeatable_content[activeTab].sectionLinkAriaLabel.value || ''}
                                >
                                    {repeatable_content[activeTab].sectionLinkText.value}
                                </a>
                            </div>
                        </div>
                        <div className="new-tab-switcher-react__image-section">
                            <figure className="new-tab-switcher-react__image-container">
                                <motion.img
                                    src={repeatable_content[activeTab].sectionImage.value}
                                    alt={repeatable_content[activeTab].sectionImageAlt.value || ''}
                                    height="466"
                                    width="699"
                                    className="new-tab-switcher-react__image"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 0.2 }}
                                />
                            </figure>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </section>
    );
};

export default NewTabSwitcher;