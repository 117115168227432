import {Props_IconsText} from './IconsText.props';
import {CompileMarkdown} from '@ClientBase/React/ReactSnippetMDCompiler';

const IconsText = (props: Props_IconsText) => {
    
    const {repeatable_card, heading} = props;
    const isVisible = heading?.displayHeading?.value === 'visible' ? 'visible' : 'hide';
    
    return (
        <section className="icons-text">
            {heading?.text?.value && <h2 className={`icons-text__main-heading ${isVisible}`}>{heading.text.value}</h2>}
            <div className="icons-text__container">
                {repeatable_card.map((card, index) => (
                    <div className="icons-text__card" key={index}>
                        {card?.imgSrc?.value && <figure className="icons-text__icon-container">
                            <img
                                src={card.imgSrc.value}
                                alt={card.imgAlt?.value ? card.imgAlt.value : ''}
                                className="icons-text__icon"
                                height="150"
                                width="150"
                            />
                        </figure>}

                        <div className="icons-text__info-container">
                            {card.heading?.value && <h2 className="icons-text__heading">{card.heading.value}</h2>
                            }
                            {card.text?.value && <div className="icons-text__text">
                                {CompileMarkdown(card.text.value)}
                            </div>}
                            {card.linkHref?.value && <a
                                href={card.linkHref.value}
                                className="icons-text__link attrax-button"
                                aria-label={card.linkAriaLabel?.value ? card.linkAriaLabel.value : ''}
                            >
                                {card.linkText?.value ? card.linkText.value : ''}
                            </a>}

                        </div>
                    </div>
                ))}

            </div>
        </section>
    );
};

export default IconsText;