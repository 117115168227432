import { useInView, useSpring, useMotionValue } from "framer-motion";
import { useEffect, useRef } from "react";
import { Props_Stats } from './Stats.props';
import { CompileMarkdown } from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

const AnimatedNumber = ({ value }: { value: number }) => {
    //I need this useRef to reference the element that will be animated in the below component, its working at a
    // local level
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });
    const motionValue = useMotionValue(0);
    const springValue = useSpring(motionValue, {
        damping: 30,
        stiffness: 30
    });

    useEffect(() => {
        if (inView) {
            motionValue.set(value);
        }
    }, [inView, value, motionValue]);

    useEffect(() => {
        return springValue.on("change", (latest) => {
            if (ref.current) {
                (ref.current as HTMLElement).textContent = Math.round(latest).toString();
            }
        });
    }, [springValue]);

    return <span ref={ref}>0</span>;
};

const Stats = (props: Props_Stats) => {
    const { heading, mainContent, repeatable_stats } = props;

    return (
        <section className="stats-react">
            <div className="stats-react__container">
                <div className="stats-react__header-section">
                    {heading?.text?.value && (
                        <h2 className="stats-react__header">{heading.text.value}</h2>
                    )}
                    {mainContent?.text?.value && (
                        <div className="stats-react__header-txt">
                            {CompileMarkdown(mainContent.text.value)}
                        </div>
                    )}
                </div>
                <div className="stats-react__stats-section">
                    {repeatable_stats.map((stat, idx) => (
                        <div
                            className={`stats-react__stat stats-react__stat--${idx + 1}`}
                            key={idx}
                        >
                            <div className="stats-react__stat-container">
                                <span className="stats-react__stat-prepend"></span>
                                <h2 className="stats-react__stat-number">
                                    <AnimatedNumber
                                        value={parseInt(stat.statNumber.value, 10)}
                                    />
                                </h2>
                                {stat?.statDecorator?.value && (
                                    <span className="stats-react__stat-append">
                                        {stat.statDecorator.value}
                                    </span>
                                )}
                            </div>
                            <p className="stats-react__stat-txt">
                                {stat.statText.value}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Stats;