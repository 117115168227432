﻿export class PostFiltersEventHandler {

    public static useAttraxTShandler($: JQueryStatic): void {

        $('.attrax-post-filters__heading-container').on('click', function () {
            $(this).parent().find('.attrax-post-filters__list').slideToggle();
            $(this).toggleClass('filter-open');

        });

    }

}
