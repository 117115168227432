﻿

export function ClickHandler($: JQueryStatic): void {
    if (window.innerWidth < 991) {
    //mobile
        $(".main-site-header__burger-menu").on("click", () => {
            $(".main-site-header__lower-section").toggleClass("nav-open");
            $("body").toggleClass("nav-open");
            $(".navList ").removeClass("open");
        });

        //icons
        $(".mainNav-widget .hasChildren > .icon").on("click", function () {
            $(this).parent().siblings().find(".open").removeClass("open");
            $(this).parent().siblings().find(".active").removeClass("active");
            $(this).parent().find("> .navList").toggleClass("open");
            $(this).toggleClass("active");
        });

        //text nodes
        const textNode: JQuery<HTMLSpanElement> = $(
            ".mainNav-widget .navList > .hasChildren > .text-node"
        );

        textNode.each((idx: number, el: HTMLElement) => {
            if ($(el).children) {
                $(el).on("click", function () {
                    $(this).parent().find("> .navList").toggleClass("open");
                    $(this)
                        .parent()
                        .siblings()
                        .find(" > .navList")
                        .removeClass("open");
                    $(this)
                        .parent()
                        .siblings()
                        .find("> .navList .links .hasChildren .navList")
                        .removeClass("open");
                    $(this)
                        .parent()
                        .siblings()
                        .find("> .icon ")
                        .removeClass("active");
                    $(this).parent().find("> .icon ").toggleClass("active");

                    const subLevelNav: JQuery<HTMLElement> = $(this)
                        .parent()
                        .find("> .navList .links .hasChildren .navList");
                    if (subLevelNav.hasClass("open")) {
                        subLevelNav.removeClass("open");
                    }
                });
            }
        });
    }   
}

export function addBackBtn($: JQueryStatic): void {
    const backBtn = "<div class='nav-back-btn'>Back</div>";

    $("li.hasChildren").each(function (idx: number, val: HTMLElement) {
        $(val).find("> .navList").prepend(backBtn);
    });

    $(".nav-back-btn").on("click", function (e) {
        e.stopPropagation();

        $(this).closest(".navList").removeClass("open");
    });
}

export function wrapNavItems($: JQueryStatic): void {
    const wrapper = '<div class="inner-nav-wrapper" />';
    const linkWrapper = '<div class="links" />';

    $(
        ".main-site-header .main-site-header__lower-section .mainNav-widget .navRoot > li .navLevel-1"
    ).each(function (id, val) {
        $(val).find("> li").wrapAll(linkWrapper);
    });

    $(".main-site-header .main-site-header__lower-section .navLevel-1").each(
        function (idx, val) {
            $(val).find("> *").wrapAll(wrapper);
        }
    );
}

export function repositionNavBanners($: JQueryStatic): void {
    $(window).on("load", function () {
        const windowWidth = $(this).width();

        if (windowWidth >= 991) {
            $(".main-site-header .navRoot > li").each(function (idx, val) {
                const banners = $(val).find(
                    "> .text-node > .nav-banner-container"
                );
                const navLevel = $(val).find(
                    "> .navLevel-1 .inner-nav-wrapper"
                );

                const detached = $(banners).detach();

                $(navLevel).append(detached);

                $(banners).css("display", "block");
            });
        }
    });
}

// Function to stop scrolling while the nav is open
// export function stopScrollWhenOpen($: JQueryStatic): void {

// $('.main-site-header .navList.navLevel-1').on('mouseenter', function() {
//     $('body').addClass('nav-open')
// })

// $('.main-site-header .navList.navLevel-1').on('mouseleave', function() {
//     $('body').removeClass('nav-open')
// })

// $(document).on('scroll', function(){
//     if ($('.main-site-header .navList.navLevel-1').css('opacity') === '1') {
//         $('.main-site-header .navList.navLevel-1').addClass('scrolled-nav')
//     } else {
//         $('.main-site-header .navList.navLevel-1').removeClass('scrolled-nav')
//     }
// })
// }

export const adjustNavWidth = () => {
    const nav: HTMLElement = document.querySelector(
        ".main-site-header .navLevel-2"
    );
    const links: NodeListOf<HTMLElement> = document.querySelectorAll(
        ".main-site-header .navList > .last > ul > .inner-nav-wrapper > .links > li"
    );
    // const isOut = isElementOut(nav) - not in use, a more direct approach below

    links.forEach((el) => {
        {
            if (el.classList.contains("hasChildren")) {
                el.querySelector<HTMLSpanElement>(".icon").addEventListener(
                    "click",
                    () => {
                        const tile: HTMLUListElement = el.querySelector("ul");
                        const tileSize: DOMRect | null = el
                            .querySelector("ul")
                            .getBoundingClientRect();

                        if (
                            tileSize.right + tileSize.width >
                            (window.innerWidth ||
                                document.documentElement.clientWidth)
                        ) {
                            tile.style.width = "fit-content";
                        }
                    }
                );
            }
        }
    });
};

export const addTitleToTextNodes = (): void => {
    const textNodes: NodeListOf<HTMLSpanElement> = document.querySelectorAll(
        ".main-site-header__lower-section .navList > li > .text-node"
    );

    if (textNodes) {
        textNodes.forEach((item) => {
            item.outerText != null
                ? item.setAttribute("title", item.outerText)
                : null;
        });
    }
};
