import {Props_ParagraphButton} from './Props_ParagraphButton'
import {CompileMarkdown} from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';

const ParagraphButton = (props: Props_ParagraphButton) => {
    
    const {repeatable_paragraph, link} = props
    
    return (
        <section className="paragraph-button">
            <div className="paragraph-button__container">
                {repeatable_paragraph.map((p, idx) => (
                    <div className="paragraph-button__txt" key={idx}>
                        {CompileMarkdown(p.text.value)}
                    </div>
                ))}

                {link?.href?.value && <a
                    href={link.href.value}
                    className="paragraph-button__link attrax-button"
                    aria-label={link.ariaLabel?.value ? link.ariaLabel.value : ''}
                >{link.text.value ? link.text.value : ''}</a
                >}

            </div>
        </section>
    )
}

export default ParagraphButton;