import {Props_Quotes} from './Quotes.props'
import {CompileMarkdown} from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

const Quotes = (props: Props_Quotes) => {
    
    const {mainHeading, quote, name, jobTitle, image} = props
    
    return (
        <section className="quotes">
            {mainHeading?.text?.value &&
                <h2 className="quotes__accesible-header">{mainHeading.text.value}</h2>
            }
            
            <div className="quotes__container">
                <div className="quotes__text-section">
                    <div className="quotes__inner-text-container">
                        {quote?.text?.value &&
                            <div className="quotes__text">
                                {CompileMarkdown(quote.text.value)}
                            </div>
                        }
                        {name?.text?.value &&
                            <p className="quotes__author">{name.text.value}</p>
                        }
                        {jobTitle?.text?.value &&
                            <p className="quote__job-title">
                                {jobTitle.text.value}
                            </p>
                        }
       
                    </div>
                </div>

                <div className="quotes__image-section">
                    {image?.src?.value &&
                        <figure className="quotes__image-container">
                            <img src={image.src.value} alt={image?.alt?.value ? image.alt.value : ''}
                                 height="552" width="524" className="quotes__image"/>
                        </figure>
                    }
           
                </div>
            </div>
        </section>
    )
}

export default Quotes