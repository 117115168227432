﻿
export function AccordionClickHandler($: JQueryStatic): void {

    $('.accordion__accordion-heading-container').on('click keypress', function (e) {



        if (e.which === 13 || 'click') {

            if ($(this).hasClass('active')) {

                $('.accordion__section').removeClass('active');
                $(this).removeClass('active');
                $(this).parent().find('.accordion__panel').slideUp();
                $(this).attr('aria-expanded', 'false');

            } else {
                $('.accordion__section').removeClass('active');
                $('.accordion__accordion-heading-container').removeClass('active').attr('aria-expanded', 'false');
                $('.accordion__panel').slideUp();
                $(this).addClass('active');
                $(this).parent().find('.accordion__panel').slideDown();
                $(this).attr('aria-expanded', 'true');
                $(this).parent().addClass('active');
                $(this).parent().addClass('active');
            }

        }

    });

}

export function ImgsLeftAccordionRightClickHandler($: JQueryStatic): void {

    $('.imgs-left-accordion-right__accordion-heading-container').on('click keypress', function (e) {



        if (e.which === 13 || 'click') {

            if ($(this).hasClass('active')) {

                $('.imgs-left-accordion-right__accordion-section').removeClass('active');
                $(this).removeClass('active');
                $(this).parent().find('.imgs-left-accordion-right__accordion-panel').slideUp();
                $(this).attr('aria-expanded', 'false');

            } else {
                $('.imgs-left-accordion-right__accordion-section').removeClass('active');
                $('.imgs-left-accordion-right__accordion-heading-container').removeClass('active').attr('aria-expanded', 'false');
                $('.imgs-left-accordion-right__accordion-panel').slideUp();
                $(this).addClass('active');
                $(this).parent().find('.imgs-left-accordion-right__accordion-panel').slideDown();
                $(this).attr('aria-expanded', 'true');
                $(this).parent().addClass('active');
                $(this).parent().addClass('active');
            }

        }

    });

}