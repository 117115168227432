import { Props_SnippetSpacer } from './Spacer.props'

const SnippetSpacer = (props: Props_SnippetSpacer) => {
    
    const {snippet} = props
    
    return (
    <div
        className='snippet-spacer'
        style={{
            height: snippet?.height?.value,
            backgroundColor: snippet?.backgroundColor?.value ?? 'transparent',
        }}
    ></div>
)}

export default SnippetSpacer