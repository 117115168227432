import {Props_SingleButton} from './SingleButton.props'
const SingleButton = (props: Props_SingleButton) => {
    const { text, position, href } = props.button;
    
    return (
            
            <div className={`centered-btn centered-btn--${position.value}`}>
                <a href={href.value} className="centered-btn__btn read-more">
                    {text.value}
                </a>
            </div>
    );
};

export default SingleButton;