declare let require: any;
import { declareClientPackage, RuntimeDetails } from "@ClientBase/IClientPackage";
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {HandleReactComponentRendering} from '@ClientBase/React/RenderReactComponents'
import { JobFiltersEventHandler } from "@ClientBase/Generic/JobFiltersEventHandler";
import { PostFiltersEventHandler } from "@ClientBase/Generic/PostFiltersEventHandler";
import { BurgerMenuEventHandler } from "@ClientBase/Generic/BurgerMenuEventHandler";
import { CreateCarousels } from "./Carousels";
import * as VideoSnippet from "./VideoSnippet";
import * as TabSwitcher from "./TabSwitcher";
import * as AccordionOld from "./Accordion";
import * as DomMainipulations from "./DomMainipulations";
import * as Navigation from "./Navigation";
import * as BrowserJobsOVerlay from "./BrowseJobsOverlay";
import * as Filters from "./Filters";
import * as StickyApply from "./StickyApply";
import * as JobTile from "./JobTile";
import * as StatsOld from "./StatsOld";
import * as CurvedCarousel from "./CurvedCarousel";
import * as NewTabSwitcherOld from "./NewTabSwitcherOld";
import * as BarChartOld from "./BarChart";
import * as JobDetails from "./JobDetails";

// REACT SNIPPETS

import SnippetSpacer from './Spacer/Spacer'
import SingleImage from './SingleImage/SingleImage'
import SingleButton from './SingleButton/SingleButton'
import Heading from './Heading/Heading'
import ImageText from './ImageText/ImageText'
import ColumsAndImages from './ColumnsAndImages/ColumnsAndImages'
import IconsText from './IconsText/IconsText'
import VideoText from './VideoAndText/VideoText'
import FullWidthVideo from './FullWidthVideo/FullWidthVideo'
import Paragraph from './Paragraph/Paragraph'
import ParagraphButton  from "./ParagrapgAndButton/ParagraphButton";
import LinkBoxes from './LinkBoxes/LinkBoxes'
import SnippetTabSwitcher from './TabSwitcher/TabSwitcher'
import Accordion from './Accordion/Accordion' 
import ImagesAndAccordion from "./ImagesAndAccordion/ImagesAndAccordion";
import StyledImagesAndText from './StyleImagesAndText/StyledImagesAndText';
import Benefits from './Benefits/Benefits'
import Icons from './Icons/Icons'
import Stats from './Stats/Stats'
import LinkGrid from './LinkGrid/LinkGrid'
import NewTabSwitcher from './NewTabSwitcher/NewTabSwitcher'
import BarChart from './BarChart/BarChart'
import Quotes from './Quotes/Quotes'
import RecruitmentCarousel from './recruitmentCarousel/recruitmentCarousel'

/**
 * Extend the ClientBase class so you can benefit from any event hooks that Attrax offers,
 * and so that Attrax knows you have defined specific methods
 */


const _Components = {
    "SnippetSpacer": SnippetSpacer, 
    "SingleImage": SingleImage,
    "SingleButton": SingleButton,
    "Heading": Heading,
    "ImageText": ImageText,
    "ColumnsAndImages": ColumsAndImages,
    "IconsText": IconsText,
    "VideoText": VideoText,
    "FullWidthVideo": FullWidthVideo,
    "Paragraph": Paragraph,
    "ParagraphButton": ParagraphButton,
    "LinkBoxes": LinkBoxes,
    "SnippetTabSwitcher": SnippetTabSwitcher,
    "Accordion": Accordion,
    "ImagesAndAccordion": ImagesAndAccordion,
    "StyledImagesAndText": StyledImagesAndText,
    "Benefits": Benefits,
    "Icons": Icons,
    "Stats": Stats,
    "LinkGrid": LinkGrid,
    "NewTabSwitcher": NewTabSwitcher,
    "BarChart": BarChart,
    "Quotes": Quotes,
    "RecruitmentCarousel": RecruitmentCarousel
}


declareClientPackage({
    onCmsSnippetPreview() {
        HandleReactComponentRendering(React, ReactDOM, _Components);
    },
    onDocumentReady(details: RuntimeDetails) {
        HandleReactComponentRendering(React, ReactDOM, _Components);
        DomMainipulations.removeSocialWidgetIfEmpty();
        JobFiltersEventHandler.useAttraxTsHandlers($);
        PostFiltersEventHandler.useAttraxTShandler($);
        BurgerMenuEventHandler.handleClickEvents($);
        VideoSnippet.ClickHandler($);
        VideoSnippet.videoBanner();
        VideoSnippet.FullWidthVideoClickHandler($);
        TabSwitcher.setHeadingonLoad();
        TabSwitcher.ClickHandler();
        AccordionOld.AccordionClickHandler($);
        AccordionOld.ImgsLeftAccordionRightClickHandler($);
        DomMainipulations.moveReadMoreButtonOnListWidgetCarousel($);
        DomMainipulations.homePageScroll($);
        DomMainipulations.moveLinkedInButton($);
        DomMainipulations.moveFilterButton($);
        DomMainipulations.injectJsResource($);
        DomMainipulations.repositionBioModal($);
        DomMainipulations.shortlistRedirect($);
        DomMainipulations.showValidationIfTrue($);
        DomMainipulations.removeActionButtons($);
        DomMainipulations.createStickyApplyHeading($);
        DomMainipulations.moveKeyword($);
        DomMainipulations.moveSendCvModal($);
        DomMainipulations.linkBoxesClickHandler($);
        DomMainipulations.removeFooterAutocomplete($);
        DomMainipulations.displayNoSavedJobsMessage($);
        DomMainipulations.showExpiredVacancyBanner($);
        DomMainipulations.addClearbtn();
        DomMainipulations.removeNonBreakingSpaceFromJobDetails();
        CreateCarousels($);
        Navigation.ClickHandler($);
        Navigation.addBackBtn($);
        Navigation.wrapNavItems($);
        Navigation.repositionNavBanners($);
        Navigation.adjustNavWidth();
        Navigation.addTitleToTextNodes();
        BrowserJobsOVerlay.ClickHandlers($);
        Filters.dropDownFilter($);
        Filters.mobileClickHandler($);
        Filters.scrollHandler($);
        StickyApply.showStickyApply($);
        JobTile.addLogo();
        JobTile.logoReplacer();
        JobTile.brandClassAssignment();
        JobTile.setActive();
        JobDetails.removeBrTags();
        CurvedCarousel.CurvedCarouselHandler($);
        NewTabSwitcherOld.snippetFunctions();
        BarChartOld.snippetFunctions();
        Filters.filterheader();
        Filters.locationClickHandler($);
        StatsOld.makeAnimate($);
        $(document).on('job-filter-initialized', function () {
            (window as any).jobFilterNoReloadController.enableDefaultFilterCounts()
        })
    },
})

