import {Props_LinkGrid} from './LinkGrid.props'

const LinkGrid = (props: Props_LinkGrid) => {
    
    const {heading, repeatable_tiles} = props
    
    console.log('PROPS:', props)
    
    return (
        <section className="img-link-grid">
            <div className="img-link-grid__container">
                    <span
                        className="img-link-grid__bg-graphic-square-secondary"
                    ></span>
                <span
                    className="img-link-grid__bg-graphic-square-highlight"
                ></span>
                {heading?.text?.value &&
                    <h2 className="img-link-grid__snippet-heading">
                        {heading.text.value}
                    </h2>
                }

                <div className="img-link-grid__tile-container">
                    {repeatable_tiles.map((tile, idx) => (
                        <div className={`img-link-grid__tile img-link-grid__tile--${idx + 1}`} key={idx}>
                            <figure className="img-link-grid__img-container">
                                <img
                                    src={tile.imageSrc.value}
                                    alt={tile.imageAlt.value ? tile.imageAlt.value : ''}
                                    className="img-link-grid__img"
                                />
                            </figure>
                            <div className="img-link-grid__txt-container">
                                <h2 className="img-link-grid__heading">{tile.tileHeading.value}</h2>
                                <a
                                    href={tile.linkHref.value}
                                    className="img-link-grid__link"
                                    aria-label={tile.linkAriaLabel.value ? tile.linkAriaLabel.value : ''}
                                >
                                    {tile.linkText.value}
                                </a>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </section>
    )
}

export default LinkGrid