import {useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import {Props_FullWidthVideo} from './Props_FullWidthVideo';
import {CompileMarkdown} from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';


const FullWidthVideo = (props: Props_FullWidthVideo) => {
    const convertIframeUrl = (url: string): string => {
        let newUrl = url;

        // Handle YouTube URLs
        if (url.includes("watch?v=")) {
            newUrl = url.replace("watch?v=", "embed/");
        } else if (url.includes("youtu.be")) {
            newUrl = url.replace("youtu.be/", "youtube.com/embed/");
        }

        // Handle Vimeo URLs
        if (url.includes("vimeo.com")) {
            const match = url.match(/vimeo\.com\/(\d+)/);
            if (match) {
                newUrl = `https://player.vimeo.com/video/${match[1]}`;
            }
        }

        // Add autoplay for all URLs if not already present
        if (!newUrl.includes("autoplay=1")) {
            newUrl = `${newUrl}${newUrl.includes("?") ? "&" : "?"}autoplay=1`;
        }

        return newUrl;
    };
    
    const {heading, repeatable_paragraph, videoThumbnail, videoSrc, playButton} = props
    const [playing, setPlaying] = useState(false)
    const formattedUrl = convertIframeUrl(videoSrc?.src?.value ? videoSrc.src.value : '')

    const popupVariants = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2,
            },
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.2,
            },
        },
    };
    
    return (
        <section className="full-width-video">
            <div className="full-width-video__container">
                <div className="full-width-video__info-container">
                    {heading?.text.value && <h2 className="full-width-video__heading">{heading.text.value}</h2>}
                    {repeatable_paragraph.map((paragraph, index) => (
                        <div key={index} className="full-width-video__txt">
                            {CompileMarkdown(paragraph.text.value)}
                        </div>
                    ))}
                </div>
                <div className="full-width-video__video-container">
                    <button
                        className="full-width-video__play-btn-container"
                        role="button"
                        aria-label={playButton?.ariaLabel?.value ? playButton.ariaLabel.value : ""}
                        onClick={() => setPlaying(!playing)}
                    >
                        <div className="full-width-video__play-btn"></div>
                        <p className="full-width-video__sub-heading">
                            {playButton?.buttonHeading?.value ? playButton.buttonHeading.value : ""}
                        </p>
                    </button>
                    <figure className="full-width-video__thumbnail-container">
                        <img
                            src={videoThumbnail.src.value}
                            alt={videoThumbnail?.alt.value ? videoThumbnail.alt.value : ""}
                            className="text-video__thumbnail"
                        />
                    </figure>
                    <AnimatePresence>
                    {playing && (
                        <motion.div 
                            className="full-width-video__pop-up full-width-video__pop-up--react" onClick={() => setPlaying(!playing)}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={popupVariants}
                        >
                            <span className="close"></span>
                            <iframe
                                width="560"
                                height="315"
                                src={formattedUrl}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </motion.div>
                    )}
                    </AnimatePresence>
                </div>
            </div>
        </section>
    )
}


export default FullWidthVideo