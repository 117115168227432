﻿export function ClickHandler($: JQueryStatic): void {
    $(".text-video").each(function (idx, val) {
        const iframe = $(val).find("iframe");
        const videoSrc: string = iframe.attr("src");
        const autoplay = `${videoSrc}?autoplay=1`;
        const playBtn = $(val).find(".text-video__play-button-container");
        const thumbnail = $(val).find(".text-video__thumbnail-container");

        $(val).find("iframe").attr("src", "");

        playBtn.on("click", function () {
            $(this).addClass("hide");
            thumbnail.addClass("hide");
            iframe.attr("src", autoplay);
            iframe.addClass("show");
        });
    });
}

export function FullWidthVideoClickHandler($: JQueryStatic): void {
    $(".full-width-video").each(function (idx, val) {
        const iframe = $(val).find("iframe");
        const videoSrc: string = iframe.attr("src");
        const autoplay = `${videoSrc}?autoplay=1`;
        const playBtn = $(val).find(".full-width-video__play-btn-container");
        const thumbnail = $(val).find(".full-width-video__thumbnail-container");
        const close = $(val).find(".close");

        $(val).find("iframe").attr("src", "");

        playBtn.on("click", function () {
            $(this).parent().find(".full-width-video__pop-up").addClass("show");
            $(".main-site-header").css("z-index", "0");
            iframe.attr("src", autoplay);
        });

        close.on("click", function () {
            $(".full-width-video__pop-up").removeClass("show");
            iframe.attr("src", " ");
            $(".main-site-header").css("z-index", "9999999");
        });
    });
}

export const videoBanner = (): void => {
    const isJobDetails = document.querySelector("body.job-details");

    if (!isJobDetails) {
        return;
    }

    const banner = document.querySelector(".job-details-cta-video");

    if (!banner) {
        return;
    } else {
        const modal = banner.querySelector(
            ".job-details-video-banner__modal-wrapper"
        );
        const close = banner.querySelector(
            ".job-details-video-banner__modal-close-btn"
        );
        const iframe = banner.querySelector("iframe");
        const src = iframe.getAttribute("src");
        const autoplay = `${src}?autoplay=1`;
        const playBtn = banner.querySelector(
            ".job-details-video-banner__btn-section"
        );
        iframe.setAttribute("src", "");

        playBtn.addEventListener("click", () => {
            iframe.setAttribute("src", autoplay);
            modal.classList.add("active");
        });

        close.addEventListener("click", () => {
            iframe.setAttribute("src", "");
            modal.classList.remove("active");
        });
    }
};
