import Swiper from "swiper";


const _createCarousel = ($: JQueryStatic, el, opt): void => {
    if (!el) return;

    const $el = $(el);
    if ($el.length) {
        $el.addClass("swiper");
        $el.find("> *").addClass("swiper-slide");
        const $pagination = $('<div class="swiper-pagination">');
        $(el).append($pagination);

        new Swiper(el, {
            createElements: true,
            navigation: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            on: {
                afterInit: (swiperInstance) => {
                    const $wrapper = $(
                        "<div class='swiper-nav-btn-container'>"
                    );
                    const $navButtons = $(swiperInstance.el).find(
                        ".swiper-button-prev, .swiper-button-next"
                    );

                    $navButtons.detach().appendTo($wrapper);
                    $(swiperInstance.el).append($wrapper);
                },
            },

            ...opt,
        });
    }
};

const _createCarouselBanner = ($: JQueryStatic): void => {
    _createCarousel($, ".carousel-cta-banner", {
        grabCursor: true,
        effect: "fade",
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
    });
};

const _createScrollingCtaCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".corp-scrolling-cta-banner", {
        grabCursor: true,
        effect: "fade",
        autoplay: {
            delay: 80000,
            disableOnInteraction: true,
        },
    });
};

const _createListWidgetCarousel = ($: JQueryStatic): void => {
    const fadeIn = () => {
        $(".jobs-carousel .attrax-list-widget__list ").addClass("show");
        $(".post-carousel .attrax-list-widget__list ").addClass("show");
    };

    _createCarousel($, ".jobs-carousel .attrax-list-widget__list", {
        slidesPerView: 1,
        a11y: true,
        navigation: true,
        spaceBetween: 28,
        afterInit: fadeIn(),
        breakpoints: {
            0: {
                slidesPerView: 1,
            },

            470: {
                slidesPerView: 2,
            },
            990: {
                slidesPerView: 3,
            },
        },
    });
};

const _createPostsCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".post-carousel .attrax-list-widget__list", {
        slidesPerView: 1,
        navigation: true,
        spaceBetween: 65,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            479: {
                slidesPerView: 2,
            },
            990: {
                slidesPerView: 3,
            },
        },
    });
};

const _createQuotesCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".corp-quotes-banner", {
        navigation: true,
        pagination: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
    });
};

const _createAwardsCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".corp-awards-banner", {
        slidesPerView: 1,
        navigation: true,
        spaceBetween: 10,
        breakpoints: {
            479: {
                slidesPerView: 2,
            },
            640: {
                slidesPerView: 3,
            },
            990: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1366: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
        },
    });
};

const _createCurvedCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".curved-carousel-cta", {
        grabCursor: true,
        effect: "fade",
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true,
        },
    });
};

const _createRecruitmentCarousel = ($: JQueryStatic): void => {
    _createCarousel($, ".recruitment-carousel__carousel-section", {
        slidesPerView: 1,
        centeredSlides: true,
        roundLengths: true,
        loop: false,
        navigation: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
        },
    });
};

export const CreateCarousels = ($: JQueryStatic): void => {
    _createScrollingCtaCarousel($),
        _createListWidgetCarousel($),
        _createPostsCarousel($),
        _createQuotesCarousel($),
        _createAwardsCarousel($);
    _createCarouselBanner($);
    _createCurvedCarousel($);
    _createRecruitmentCarousel($);
};
