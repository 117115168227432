export const snippetFunctions = () => {
    const snippets: NodeListOf<HTMLDivElement> =
        document.querySelectorAll(".bar-chart");

    if (!snippets) {
        return;
    }

    snippets.forEach((snippet) => {
        cloneCard(snippet);
        clickHandler(snippet);
    });
};

function clickHandler(snippet) {
    const bars: NodeListOf<HTMLDivElement> =
        snippet.querySelectorAll(".bar-chart__bar");

    bars.forEach((bar) => {
        bar.addEventListener("click", () => {
            bars.forEach((otherbar) => {
                otherbar.classList.remove(`${otherbar.classList[0]}--active`);
            });

            bar.classList.add(`${bar.classList[0]}--active`);
            cloneCard(snippet);
        });
    });
}

function cloneCard(snippet) {
    const cardsection: HTMLDivElement = snippet.querySelector(
        ".bar-chart__card-section"
    );

    const activeBar: HTMLDivElement = snippet.querySelector(
        ".bar-chart__bar--active"
    );

    if (cardsection.classList.contains(`${cardsection.classList[0]}--active`)) {
        cardsection.classList.remove(`${cardsection.classList[0]}--active`);
    }

    setTimeout(() => {
        cardsection.innerHTML = activeBar.innerHTML;
        cardsection.classList.add(`${cardsection.classList[0]}--active`);
    }, 500);
}
