

function animateCounter(
    el: HTMLElement,
    start = 0,
    end: null | number = null,
    duration = 3000
): void {
    let originalCounter: string = el.dataset.count;
    end = end || parseFloat(originalCounter);
    const range: number = end - start;
    let stepTime: number = Math.abs(duration / range);
    stepTime = Math.max(stepTime, 50);

    const startTime = new Date().getTime();
    const endTime = startTime + duration;
    let timer;

    function run(): void {
        const now = new Date().getTime();
        const remaining = Math.max((endTime - now) / duration, 0);
        let value: any;

        if (originalCounter.indexOf(".") > -1) {
            value = (end - remaining * range).toFixed(1);
        } else {
            value = Math.round(end - remaining * range);
        }
        el.innerHTML = value.toString();
        if (value === end) {
            clearInterval(timer);
        }
    }

    if (el) {
        timer = setInterval(run, stepTime);
        run();
    }
}

export const makeAnimate = ($: JQueryStatic): void => {
    const counters: JQuery = $(".stats__stat-number");

    const fadeInEls: NodeListOf<Element> =
        document.querySelectorAll(".stats__stat-number");

    if ("IntersectionObserver" in window) {
        const config: {
            root: any;
            rootMargin: string;
            threshold: number;
        } = {
            root: null,
            rootMargin: "0px",
            threshold: 1,
        };

        const observer: IntersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        counters.each(function (
                            idx: number,
                            counter: HTMLElement
                        ) {
                            animateCounter(counter);
                        });

                        observer.unobserve(entry.target);
                    }
                });
            },
            config
        );

        fadeInEls.forEach((el) => {
            observer.observe(el);
        });
    }
};
