import {Props_StyledImagesAndText} from './StyledImagesAndText.props'
import {CompileMarkdown} from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

const StyledImagesAndText = (props: Props_StyledImagesAndText) => {
    
    const {heading, imageOne, imageTwo, content, link} = props
    
    return (
        <section className="imgs-and-text">
            <div className="imgs-and-text__container">
                <div className="imgs-and-text__img-section">
                        <span
                            className="imgs-and-text__img-section-graphic-square"
                        ></span>
                    {imageOne?.src?.value && <figure
                        className="imgs-and-text__img-container imgs-and-text__img-container--bottom"
                    >
                        <img
                            src={imageOne.src.value}
                            alt={imageOne?.altTex?.value ? imageOne.altTex.value : ""}
                            className="imgs-and-text__img"
                            width="386"
                            height="258"
                        />
                    </figure>}

                    <span
                        className="imgs-and-text__img-section-graphic-triangle"
                    ></span>
                    {imageTwo?.src?.value && <figure
                        className="imgs-and-text__img-container imgs-and-text__img-container--top"
                    >
                        <img
                            src={imageTwo?.src?.value}
                            alt={imageTwo?.altTex?.value ? imageTwo.altTex.value : ""}
                            className="imgs-and-text__img"
                            width="258"
                            height="360"
                        />
                    </figure>}

                </div>
                <div className="imgs-and-text__text-section">
                    <div className="imgs-and-text__text-container">
                        {heading?.text?.value && <h2 className="imgs-and-text__heading">
                            {heading.text.value}
                        </h2>}

                        {content?.text?.value && <div className="imgs-and-text__txt">
                            {CompileMarkdown(content.text.value)}
                        </div>}

                        {link?.href?.value && <a
                            href={link.href.value}
                            className="imgs-and-text__link outline-btn"
                            aria-label={link.ariaLabel.value ? link.ariaLabel.value : ""}
                        >
                            {link?.text?.value ? link.text.value : ""}
                        </a>}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default StyledImagesAndText