﻿
import { upOrDownOnScrollWheel } from "./HelperFunctions";

export function scrollHandler($: JQueryStatic): void {
    upOrDownOnScrollWheel($(".job-results__filters-container"));
    upOrDownOnScrollWheel($(".post-results__filters-container"));
}

export function dropDownFilter($: JQueryStatic): void {
    // Add 'content type' heading and toggle on click
    //$('.content-types-widget').prepend(`<div class="attrax-post-filters__heading-container attrax-post-filters__content-type"><p class="attrax-post-filters__heading">Content type</p></div>`)
    $(".content-types-widget__heading").on("click", function () {
        $(this).parent().find("ul").slideToggle();
        $(this).toggleClass("open-filter");
    });
}

export function mobileClickHandler($: JQueryStatic): void {
    $(".post-results__filter-btn").on("click", function () {
        $(".post-results__filter-wrapper").slideToggle();
        $(this).toggleClass("filters-open");
    });
}

export const filterheader = () => {
    const filterheading = document.querySelector(
        ".search-filters-widget__heading"
    );

    if (!filterheading) return;

    filterheading.innerHTML = "Filters";
    filterheading.classList.add("search-filters-widget__heading--active");
};


export const locationClickHandler = ($: JQueryStatic) => {
    $("#jobfilters .location-filter .header").on("click", () => {
        $(".location-slider-wrapper").toggleClass("open");
        $(".location-slider-wrapper").slideToggle();
    });
};