

export function moveReadMoreButtonOnListWidgetCarousel($: JQueryStatic): void {
    $(".jobs-carousel, .post-carousel, .jobs-section").each(function (
        idx: number,
        val: HTMLElement
    ) {
        const btn: JQuery = $(val).find(
            ".attrax-list-widget__list > .attrax-button"
        );
        const detached: JQuery<HTMLElement> = btn.detach();

        $(val).find(".attrax-list-widget__lists").append(detached);
    });
}

export function homePageScroll($: JQueryStatic): void {
    $(".homepage__scroll-btn-hero").on("click", function () {
        const heroHeight: number = $(".homepage__hero").innerHeight();

        $("html, body").animate(
            {
                scrollTop: heroHeight,
            },
            1500
        );
    });
}

export function moveLinkedInButton($: JQueryStatic): void {
    const linkedIn: JQuery = $(
        ".bio .attrax-social-hub-profile-widget .sh-linkedin-button"
    ).detach();

    $(".bio .attrax-user-profile-contact-buttons-widget__buttons").append(
        linkedIn
    );
}

export function moveFilterButton($: JQueryStatic): void {
    $(".optionfiltergroup").each(function (idx: number, val: HTMLElement) {
        const clearBtn: JQuery = $(val)
            .find(".job-filter-clear-group-btn")
            .detach();

        $(val).find(".apply-filters-btn-container").append(clearBtn);
    });
}

export function injectJsResource($: JQueryStatic): void {
    // resources for post sign up
    const resourceOne = "<span class='signup-one'>{{js.signup-one}}</span>";
    const resourceTwo = "<span class='signup-two'>{{js.signup-two}}</span>";

    $(".edit-post-alert").prepend(resourceOne).append(resourceTwo);
}

export function repositionBioModal($: JQueryStatic): void {
    const bioModal = $(
        ".attrax-social-hub-profile-widget .attrax-popup-modal"
    ).detach();

    $("body").append(bioModal);
}

export function shortlistRedirect($: JQueryStatic): void {
    $(".inline-shortlist-widget").on("click", function () {
        window.location.href = "/candidateshortlist";
    });
}

export function showValidationIfTrue($: JQueryStatic): void {
    $(".attrax-login-widget__btn-login").on("click", function () {
        if (
            $(".attrax-login-widget__validation-summary").children().length >= 1
        ) {
            $(".attrax-login-widget__validation-summary").css(
                "display",
                "block"
            );
        }
    });
}

export function removeActionButtons($: JQueryStatic): void {
    const windowWidth = $(window).width();

    if (windowWidth >= 991) {
        if (
            !$("body").hasClass("homepage") &&
            !$("body").hasClass("job-results")
        ) {
            $(
                ".main-site-header__lower-section .main-site-header__action-btn-container"
            ).remove();
        }

        if ($("body").hasClass("homepage")) {
            $(
                ".main-site-header__upper-section .main-site-header__action-btn-container"
            ).remove();
        }
    }

    if (windowWidth < 991) {
        $(
            ".main-site-header__lower-section .main-site-header__action-btn-container"
        ).remove();
    }
}

export function createStickyApplyHeading($: JQueryStatic): void {
    if ($("body").hasClass("job-details")) {
        const jobTitle = $(".title-widget .header__text").text();

        $(".stick-apply__heading").text(jobTitle);
    }
}

export function moveKeyword($: JQueryStatic): void {
    const keyword = $("#keyword-option-wrapper").detach();

    // $('.job-results__main-content-container').prepend(keyword);
    $(".job-results__hero").append(keyword);
}

export function moveSendCvModal($: JQueryStatic): void {
    const sendCvForm = $(
        ".bio .attrax-user-profile-contact-buttons-widget__sendcv-modal"
    ).detach();

    $(".bio").append(sendCvForm);
}

export function removeFooterAutocomplete($: JQueryStatic): void {
    $(".footer__search .attrax-form-item__input").on("focus", function () {
        $(".autocomplete-suggestions").addClass("footer-focused");
    });

    $(".footer__search .attrax-form-item__input").on("blur", function () {
        setTimeout(() => {
            $(".autocomplete-suggestions").removeClass("footer-focused");
        }, 2000);
    });
}

export function linkBoxesClickHandler($: JQueryStatic): void {
    $(".link-boxes__link-box").on("click", function () {
        const linkHref = $(this).find(".link-boxes__link--hide").attr("href");

        window.location.href = linkHref;
    });
}

export function displayNoSavedJobsMessage($: JQueryStatic): void {
    const noJobsInShortlist = $(
        "body.candidate-shortlist-page .attrax-list-widget--no-items"
    ).length;

    if (noJobsInShortlist >= 1) {
        $("body.candidate-shortlist-page .paragraph-button").css(
            "display",
            "block"
        );
    }
}

export function showExpiredVacancyBanner($: JQueryStatic): void {
    const isExpired = $("body.job-details .validation-summary-errors").length;

    if (isExpired >= 1) {
        $(".corp-expired-vacancy").addClass("show");
    }
}

export const addClearbtn = () => {
    const widgets: string[] = [
        "content-types-widget",
        "tags-widget",
        "attrax-post-filters",
    ];

    widgets.forEach((widget) => {
        if (document.querySelector(".post-results ." + widget)) {
            const widgetList: HTMLElement = document
                .querySelector(".post-results ." + widget)
                .querySelector("ul");
            const clearli: HTMLElement = document.createElement("li");
            clearli.classList.add(`${widget}__clear-btn-li`);
            const clearBTn: HTMLElement = document.createElement("a");
            clearBTn.classList.add(`${widget}__clear-btn`);
            clearBTn.classList.add(`btn`);
            clearBTn.setAttribute("href", "/posts");
            clearBTn.setAttribute(
                "aria-label",
                "click here to clear selected types"
            );
            clearBTn.textContent = "Clear";
            clearli.appendChild(clearBTn);
            widgetList?.appendChild(clearli);
        }
    });
};

export const removeNonBreakingSpaceFromJobDetails = () => {
    const jobDetails: HTMLDivElement = document.querySelector(
        ".job-details__main-content"
    );
    if (document.body.classList.contains("job-details") && jobDetails) {
        const emptyParagraphs: NodeListOf<HTMLParagraphElement> =
            jobDetails.querySelectorAll("p");
        emptyParagraphs.forEach((paragraph) => {
            if (paragraph.textContent.length <= 1) {
                paragraph.remove();
            }
        });
    }
};

export const removeSocialWidgetIfEmpty = () => {
    const jobDetails: HTMLDivElement = document.querySelector(
        ".job-details__main-content"
    );

    if (document.body.classList.contains("job-details") && jobDetails) {
        if (
            jobDetails.querySelector(".social-hub-profile-widget")
                .childElementCount === 0
        ) {
            jobDetails.querySelector<HTMLDivElement>(
                ".social-hub-profile-widget"
            ).style.opacity = "0";
        }
    }
};
