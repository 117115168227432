import {Props_SingleImage} from "./SingleImage.props";

const SingleImage = (props: Props_SingleImage) => {
    
    const {image} = props;
    
    return (
        <div className="single-image">
            <img
                className="single-image__img"
                src={image.src.value}
                height={image.height.value}
                width={image.width.value}
                alt={image.alt.value}
            />
        </div>
    );
};

export default SingleImage;