﻿function closeOptionFilterGroups(targetButtons: Element[]): void {
    targetButtons.forEach((btn) => {
        btn.setAttribute('aria-expanded', 'false');
        const filterGroup: HTMLElement | null = btn.closest('.optionfiltergroup');
        if (filterGroup) {
            const displayList: HTMLElement | null = filterGroup?.querySelector('.option-display-list');
            displayList?.classList.remove('option-display-list--open');
        }
    });
}

function toggleOptionsListAndGroup(button: HTMLElement): void {
    const container: HTMLElement | null = button.closest('.optionfiltergroup');
    if (container) {
        const ul: HTMLElement | null = container.querySelector('.option-display-list');
        if (ul) {
            const isExpanded = button.getAttribute('aria-expanded') === 'true';
            button.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
            if(button.getAttribute('aria-expanded') === 'true') {
                ul.classList.add('option-display-list--open');
            } else {
                ul.classList.remove('option-display-list--open');
            }
            const listOptions: NodeListOf<HTMLLIElement> = ul.querySelectorAll('.filter-contents');
            listOptions[0].focus();
        }
    }
}

export class JobFiltersEventHandler {
    public static useAttraxTsHandlers($: JQueryStatic): void {
        $('.search-filters-widget__heading').on('click', function () {
            $(this).siblings('#jobfilters').slideToggle();
            $(this).toggleClass('filters-open');
        });

        $('.filter-expand-hide').on('click', function () {
            $(this).siblings('.child-list').slideToggle();
            $(this).toggleClass('filters-open');
        });

        const $salarySliderOptions = $('.salary-slider__options');
        const options = $salarySliderOptions.detach();

        $('.salary-slider__container').prepend(options);

        $salarySliderOptions.css('display', 'block');

        $('.salary-slider__header').on('click', function () {
            $(this).toggleClass('open');
            $(this).parent().find('.salary-slider__container').slideToggle();
        });


        const filterToggleButtons = document.querySelectorAll('button.option-type-header-text');
        filterToggleButtons.forEach((button: HTMLButtonElement, idx) => {
            const otherButtons = Array.from(filterToggleButtons).filter(btn => btn !== button);
            button.addEventListener('click', function () {
                closeOptionFilterGroups(otherButtons);
                toggleOptionsListAndGroup(button);
            });
        });


        document.body.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;
            if(!target.matches('.option-type-header-text') && !target.closest('.option-display-list')) {
                closeOptionFilterGroups(Array.from(filterToggleButtons));
            }
        });

    }
}
