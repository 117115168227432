import {Props_Icons} from './Icons.props'

const Icons = (props: Props_Icons) => {

    const {repeatable_section} = props
    
    return (
        <section className="icons">
            <div className="icons__container">
                {repeatable_section.map((section,idx) => (
                    <div className={`icons__icon icons__icon--${idx + 1}`} key={idx}>
                        <a href={section.linkHref.value} aria-label={section?.linkAriaLabel?.value ? section.linkAriaLabel.value : ''}>
                            <figure className="icons__image-container">
                                <img
                                    src={section.imageSrc.value}
                                    alt={section?.imageAlt?.value ? section.imageAlt.value : ''}
                                    height="90"
                                    width="90"
                                    className="icons__image"
                                />
                            </figure>
                            <h3 className="icons__icon-heading">{section.linkText.value}</h3>
                        </a>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Icons