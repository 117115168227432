import {Props_RecruitmentCarousel} from './recruitmentCarousel.props'
import {CompileMarkdown} from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination, Navigation} from 'swiper';


SwiperCore.use([Pagination, Navigation]);
const RecruitmentCarousel = (props: Props_RecruitmentCarousel) => {
    
    const {mainHeading, mainParagraph, repeatable_tile} = props

    const swiperConfig = {
        slidesPerView: 1,
        centeredSlides: true,
        roundLengths: true,
        loop: false,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: '.recruitment-carousel__next',
            prevEl: '.recruitment-carousel__prev',
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
        },
    };
    
    
    return (
        <section className="recruitment-carousel">
            <div className="recruitment-carousel__container">
                <div className="recruitment-carousel__header-section">
                    {mainHeading?.text?.value &&
                        <h2 className="recruitment-carousel__header">
                            {mainHeading.text.value}
                        </h2>
                    }
                    {mainParagraph?.text?.value &&
                        <div className="recruitment-carousel__text">
                            {CompileMarkdown(mainParagraph.text.value)}
                        </div>
                    }
                </div>
            </div>

            <div className="recruitment-carousel__carousel-section">
                <div className="recruitment-carousel__navigation-wrapper swiper-nav-btn-container">
                    <button className="recruitment-carousel__next swiper-button-prev swiper-button-prev--react"></button>
                    <button className="recruitment-carousel__prev swiper-button-next swiper-button-next--react"></button>
                </div>
                <Swiper {...swiperConfig} pagination={{clickable: true}}>
                {repeatable_tile.map((tile, index) => (
                    <SwiperSlide key={index}>
                    <div className={`recruitment-carousel__process recruitment-carousel__process--${index + 1}`}>
                        <div className="recruitment-carousel__process-heading-section">
                            <h3 className="recruitment-carousel__process-number">
                                {index + 1}
                            </h3>
                            <p className="recruitment-carousel__process-heading-text">
                                {tile.tileHeading.value}
                            </p>
                            <figure className="recruitment-carousel__image-container">
                                <img src={tile.tileImage.value} alt={tile.tileImageAlt.value ? tile.tileImageAlt.value : ''} width="90"
                                     height="90" className="recruitment-carousel__image"/>
                            </figure>
                        </div>
                        <div className="recruitment-carousel__process__content-section">
                            <div className="recruitment-carousel__process__text">
                                {CompileMarkdown(tile.tileContent.value)}
                            </div>
                            <a href={tile.tileLink.value} className="recruitment-carousel__link btn"
                               aria-label={tile.tileImageAlt.value ? tile.tileImageAlt.value : ''}>{tile.tileLinkText.value}</a>
                        </div>
                    </div>
                    </SwiperSlide>

                ))}
                </Swiper>
            </div>
        </section>

    )
}

export default RecruitmentCarousel