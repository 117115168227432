export const snippetFunctions = (): void => {
    const snippets: NodeListOf<HTMLDivElement> = document.querySelectorAll(".new-tab-switcher");

    if (!snippets || snippets.length === 0) {
        return;
    }

    snippets.forEach(setupTabSwitcher);
};

function setupTabSwitcher(snippet: HTMLDivElement): void {
    cloneBody(snippet);

    const tabHeadings: NodeListOf<HTMLDivElement> = snippet.querySelectorAll(
        ".new-tab-switcher__heading"
    );

    tabHeadings.forEach(tab => {
        tab.addEventListener("click", () => {
            if (!tab.classList.contains(`${tab.classList[0]}--active`)) {
                tabHeadings.forEach(otherTab => {
                    otherTab.classList.remove(`${otherTab.classList[0]}--active`);
                });

                tab.classList.add(`${tab.classList[0]}--active`);
                cloneBody(snippet);
            }
        });
    });
}

function cloneBody(snippet: HTMLDivElement): void {
    const bodySection = snippet.querySelector<HTMLDivElement>(".new-tab-switcher__body-section");
    if (!bodySection) return;

    const activeHeading = snippet.querySelector<HTMLDivElement>(".new-tab-switcher__heading--active");
    if (!activeHeading) return;

    const activeBody = activeHeading.querySelector<HTMLDivElement>(".new-tab-switcher__body");
    if (!activeBody) return;

    bodySection.classList.remove("new-tab-switcher__body-section--active");

    setTimeout(() => {
        bodySection.innerHTML = '';

        const contentClone = activeBody.cloneNode(true) as HTMLDivElement;

        while (contentClone.firstChild) {
            bodySection.appendChild(contentClone.firstChild);
        }

        requestAnimationFrame(() => {
            bodySection.classList.add("new-tab-switcher__body-section--active");
        });
    }, 150);
}