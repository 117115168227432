import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Props_Accordion } from './Accordion.props';
import { CompileMarkdown } from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';


const panelVariants = {
    initial: {
        height: 0,
        opacity: 0
    },
    animate: {
        height: "auto",
        opacity: 1,
        transition: {
            height: {
                duration: 0.3,
                ease: "easeOut"
            },
            opacity: {
                duration: 0.25,
                delay: 0.1
            }
        }
    },
    exit: {
        height: 0,
        opacity: 0,
        transition: {
            height: {
                duration: 0.3,
                ease: "easeIn"
            },
            opacity: {
                duration: 0.2
            }
        }
    }
};


const Accordion = (props: Props_Accordion) => {
    const [expandedSection, setExpandedSection] = useState<number | null>(null);

    const { heading, repeatable_section } = props;

    const accesibleHeading = !heading.isVisable.value ? 'accessible-heading' : '';

    const toggleSection = (index: number) => {
        setExpandedSection(prev => (prev === index ? null : index));
    };
    

    const isExpanded = (index: number) => expandedSection === index;

    return (
        <section className="accordion">
            <div className="accordion__container">
                {heading?.text.value && (
                    <h2 className={`accordion__heading ${accesibleHeading}`}>
                        {heading.text.value}
                    </h2>
                )}
                {repeatable_section.map((section, idx) => (
                    <div className="accordion__accordion" key={idx}>
                        <div className="accordion__section">
                            <button
                                className={`accordion__accordion-heading-container ${isExpanded(idx) ? 'active' : ''}`}
                                onClick={() => toggleSection(idx)}
                                aria-expanded={isExpanded(idx)}
                            >
                                {section?.title?.value}
                            </button>
                            <AnimatePresence initial={false}>
                                {isExpanded(idx) && (
                                    <motion.div
                                        className="accordion__panel accordion__panel--react"
                                        variants={panelVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        style={{ overflow: "hidden" }}
                                    >
                                        <div className='accordion__panel-txt'>
                                            {CompileMarkdown(section?.content?.value)}
                                        </div>
                                        {section?.linkText?.value && (
                                            <div className="accordion__link-container">
                                                <a
                                                    href={section?.linkHref?.value}
                                                    aria-label={section?.linkAriaLabel?.value}
                                                    className="accordion__link attrax-button"
                                                >
                                                    {section.linkText.value}
                                                </a>
                                            </div>
                                        )}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Accordion;