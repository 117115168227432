import { compiler } from "markdown-to-jsx";

export const CompileMarkdown = (content: string) => {
  return compiler(content.trim() + '\n\n', {
    forceWrapper: true,
    wrapper: 'div',
    overrides: {
      span: 'p',
    },
    disableParsingRawHTML: true
  });
};