import {Props_Benefits} from './Benefits.props'
import {CompileMarkdown} from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

const Benefits = (props: Props_Benefits) => {
    
    const {heading, repeatable_benefits, discoverMoreLink} = props
    
    return (
        <section className="benefits">
            <div className="benefits__container">
                <div className="benefits__heading-section">
                    {heading?.text?.value && <h2 className="benefits__heading">
                        {heading.text.value}
                        <span className="benefits__heading--color">{heading.highlightedText.value}</span>
                    </h2>}

                </div>
                <div className="benefits__benefits-section">
                    {repeatable_benefits.map((benefit, index) => (
                        <div className="benefits__benefit benefits__benefit--1" key={index}>
                            {benefit?.image.value && <figure className="benefits__image-container">
                                <img src={benefit.image.value} alt={benefit?.imageAlt?.value ? benefit.imageAlt.value : ''} height="90" width="90"
                                     className="benefits__image"/>
                            </figure>}

                            {benefit?.heading?.value && <h3 className="benefits__benefit-heading">
                                {benefit.heading.value}
                            </h3>}

                            {benefit?.content?.value && <div className="benefits__benefit-text">
                                {CompileMarkdown(benefit.content.value)}
                            </div>}

                            {benefit?.linkHref?.value && <a href={benefit.linkHref.value} className="benefits__benefit-link"
                                                            aria-label={benefit.linkAria.value}></a>}

                        </div>
                    ))}

                </div>
                {discoverMoreLink?.href?.value && 
                    <a href={discoverMoreLink?.href?.value} className="benefits__link read-more" aria-label={discoverMoreLink?.ariaLabel?.value ? discoverMoreLink.ariaLabel.value : ''}>Discover More</a>
                }

            </div>
        </section>
    )
}

export default Benefits