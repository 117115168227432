import React from 'react';
import {Props_ColumnsAndImages} from './ColumnsAndImages.props'
import {CompileMarkdown} from '@ClientBase/React/ReactSnippetMDCompiler';

const ColumsAndImage = (props: Props_ColumnsAndImages) => {
    const {heading, repeatable_card} = props;

    return (
        <section className="two-col-img-txt">
            <div className="two-col-img-txt__container">
                <h2 className="two-col-img-txt__heading">{heading.text.value}</h2>
                <div className="two-col-img-txt__card-container">
                    {repeatable_card.map((col, index) => (
                        <div key={index} className={`two-col-img-txt__col`}>
                            {col?.imgSrc.value && <figure className="two-col-img-txt__img-container">
                                <img
                                    src={col.imgSrc.value}
                                    width="300"
                                    height="300"
                                    alt={col?.imgAlt?.value ? col.imgAlt.value : ''}
                                />
                            </figure>}

                            {col?.subHeading?.value && (
                                <h3 className="two-col-img-txt__sub-heading">{col.subHeading.value}</h3>
                            )}
                            {col?.texts?.value && <div>{CompileMarkdown(col.texts.value)}</div>}
                            {col?.linkHref?.value && (
                                <a
                                    href={col.linkHref.value}
                                    className="two-col-img-txt__link attrax-button"
                                    aria-label={col?.linkAriaLabel?.value ? col.linkAriaLabel.value : ''}
                                >
                                    {col?.linkText?.value ? col.linkText.value : 'Read More'}
                                </a>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ColumsAndImage;