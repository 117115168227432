export function removeBrTags() {
    if (!document.body.classList.contains('job-details')) {
        return;
    }

    const jobDetails = document.querySelector('.job-details__main-content');
    if (!jobDetails) {
        return;
    }

    const brTags = jobDetails.querySelectorAll('br');
    brTags.forEach(brTag => {
        brTag.remove();
    });

    jobDetails.classList.add('job-details__main-content--tags-removed');
}