

export function CurvedCarouselHandler($: JQueryStatic): void {

    $(".curved-carousel.banner-listings-wrapper").addClass("curved-carousel__slider-container");
    $(".curved-carousel .banner-wrapper").addClass("curved-carousel__slide-container");
    $(".curved-carousel .banner-customhtml").addClass("curved-carousel__slide");
    $('<h2 class="curved-carousel__banner-heading">Meet the team</h2>').prependTo(".curved-carousel__wrapper");
    $('<div class="curved-carousel__buttons"><button class="curved-carousel__button-prev prev" aria-label="Previous slide"></button><button class="curved-carousel__button-next next" aria-label="Next slide"></button></div>').appendTo(".curved-carousel__slider-container");
    
    const slides = $(".curved-carousel__slide-container");
    var activeSlideIndex = 1;

    updateSlider(activeSlideIndex);

    function updateSlider(way) {
        var slideOnLeft,
            slideOnRight,
            slideOnCenter,
            slideToLoad,
            SlideToLoadRotation,
            SlideToUnLoad,
            SlideToUnLoadRotation;

        if (way == "next") {
            activeSlideIndex--;
        } else if (way == "prev") {
            activeSlideIndex++;
        }
        updateIndex();

        slideOnCenter = slides[activeSlideIndex];

        if (activeSlideIndex === slides.length - 1) {
            slideOnRight = slides[0];
        } else {
            slideOnRight = slides[activeSlideIndex + 1];
        }

        if (activeSlideIndex === 0) {
            slideOnLeft = slides[slides.length - 1];
        } else {
            slideOnLeft = slides[activeSlideIndex - 1];
        }

        if (way == "next") {
            slideToLoad = slideOnRight;
            SlideToLoadRotation = -90;
            SlideToUnLoad = slides[activeSlideIndex - 2];
            SlideToUnLoadRotation = 90;
        } else if (way == "prev") {
            slideToLoad = slideOnLeft;
            SlideToLoadRotation = 90;
            SlideToUnLoad = slides[activeSlideIndex + 2];
            SlideToUnLoadRotation = -90;
        }

        if (activeSlideIndex === 0 && way == "next") {
            SlideToUnLoad = slides[slides.length - 2];
        }
        if (activeSlideIndex === 1 && way == "next") {
            SlideToUnLoad = slides[slides.length - 1];
        }
        if (activeSlideIndex === 0 && way == "prev") {
            SlideToUnLoad = slides[2];
        }
        if (activeSlideIndex === 1 && way == "prev") {
            SlideToUnLoad = slides[3];
        }
        if (activeSlideIndex === slides.length - 1 && way == "prev") {
            SlideToUnLoad = slides[1];
        }
        if (activeSlideIndex === slides.length - 2 && way == "prev") {
            SlideToUnLoad = slides[0];
        }


        $(".curved-carousel__slide-container--active").removeClass("curved-carousel__slide-container--active");
        $(slideOnCenter).addClass("curved-carousel__slide-container--active");

        $(".curved-carousel__slide-container--active-left").removeClass("curved-carousel__slide-container--active-left");
        $(slideOnLeft).addClass("curved-carousel__slide-container--active-left");

        $(".curved-carousel__slide-container--active-right").removeClass("curved-carousel__slide-container--active-right");
        $(slideOnRight).addClass("curved-carousel__slide-container--active-right");
    }

    function updateIndex() {
        if (activeSlideIndex < 0) {
            activeSlideIndex = slides.length - 1;
        } else if (activeSlideIndex > slides.length - 1) {
            activeSlideIndex = 0;
        }
    }

    $(".prev").click(function () {
    updateSlider("next");
    });
    $(".next").click(function () {
    updateSlider("prev");
    });
}





