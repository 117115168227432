import {Props_Heading} from './Heading.props'

const Heading = (props: Props_Heading) => {
    
    const {text, level} = props.heading 
    
    switch (level.value) {
        case 'h2':
            return <h2 className={'single-heading'}>{text.value}</h2>
        case 'h3':
            return <h3 className={'single-heading'}>{text.value}</h3>
        case 'h4':
            return <h4 className={'single-heading'}>{text.value}</h4>
        case 'h5':
            return <h5 className={'single-heading'}>{text.value}</h5>
        case 'h6':
            return <h6 className={'single-heading'}>{text.value}</h6>
        default:
            return <h2 className={'single-heading'}>{text.value}</h2>
    }
    
}

export default Heading