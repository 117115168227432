export interface RuntimeDetails {
    jqInstance: JQueryStatic
}

interface SnippetPreviewDetails {}

interface ClientPackage {
    onDocumentReady(run: RuntimeDetails): void,
    onCmsSnippetPreview?(prv: SnippetPreviewDetails): void,
}


export const declareClientPackage = (p: ClientPackage) => {
    window["ClientPackage"] = p;
}
