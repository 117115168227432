type ReactElementCreator = {
    createElement: (component: any, props: Record<string, any>) => any;
}

type ReactRootCreator = {
    createRoot: (element: HTMLElement) => any;
}



export const HandleReactComponentRendering = (
    React: ReactElementCreator,
    ReactDOM: ReactRootCreator,
    componentDictionary: Record<string, any>
    ) => {

    const componentsOnPage = document.querySelectorAll("script[type='application/attraxreactsnippet']");


    componentsOnPage.forEach((component) => {
        const sibling = document.createElement("div");
        sibling.classList.add('attrax-react-rendered');
        const componentType = component.getAttribute("data-component-type");

        if (!componentType) {
            console.error('Component type is missing');
            return;
        }


        const Component = componentDictionary[componentType];
        if (!Component) {
            console.error(`Component type "${componentType}" is not defined in componentDictionary`);
            return;
        }



        let componentProps: Record<string, any> = {};
        if (component.innerHTML) {
            try {
                const props = component.innerHTML.trim();
                if (props) {
                    componentProps = JSON.parse(props);
                    componentProps = convertToReactPropertiesForFrontEnds(componentProps);
                }
            } catch (error) {
                console.error("Error parsing component props:", error);
            }
        }


        const el = React.createElement(Component, componentProps);
        const parent = component.parentElement;

        const existingContainer = parent?.querySelector('.react-snippet-component') as HTMLElement;

        if (existingContainer) {
            ReactDOM.createRoot(existingContainer).render(el);
        } else {
            const sibling = document.createElement("div");
            sibling.classList.add('attrax-react-rendered');
            ReactDOM.createRoot(sibling).render(el);
            component.insertAdjacentElement("afterend", sibling);
        }

    });

}


export const convertToReactPropertiesForFrontEnds = (obj: any, keyProperty: string = 'name'): any => {
    if (Array.isArray(obj)) {
        return obj.reduce((acc, item) => {
            if (item && item[keyProperty]) {
                const { [keyProperty]: key, ...rest } = item;
                acc[key] = convertToReactPropertiesForFrontEnds(rest, keyProperty);
            }
            return acc;
        }, {});
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (key.startsWith('repeatable_') && Array.isArray(value)) {
                acc[key] = value.map(item => convertToReactPropertiesForFrontEnds(item, keyProperty));
            } else if (value && value.type === 'optionsList' && Array.isArray(value.options)) {
                acc[key] = value;
            } else {
                acc[key] = convertToReactPropertiesForFrontEnds(value, keyProperty);
            }
            return acc;
        }, {} as Record<string, any>);
    }
    return obj;
};


(window as any).HandleReactComponentRendering = HandleReactComponentRendering;
