import { motion, AnimatePresence, Variants } from "framer-motion";
import { useState } from "react";
import { Props_ImagesAndAccordion } from './ImagesAndAccordion.props';
import { CompileMarkdown } from "../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler";

// Motion variants for the accordion panel
const accordionPanelVariants: Variants = {
    open: {
        height: "auto",
        opacity: 1,
        transition: {
            duration: process.env.NODE_ENV === 'test' ? 0 : 0.3,
            ease: "easeOut"
        }
    },
    collapsed: {
        height: 0,
        opacity: 0,
        transition: {
            duration: process.env.NODE_ENV === 'test' ? 0 : 0.2,
            ease: "easeIn"
        }
    }
};

const ImagesAndAccordion = (props: Props_ImagesAndAccordion) => {
    const { heading, imageOne, imageTwo, repeatable_section } = props;
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    // If we're in test environment, don't use AnimatePresence
    const shouldAnimate = process.env.NODE_ENV !== 'test';

    return (
        <section className="imgs-left-accordion-right">
            <div className="imgs-left-accordion-right__container">
                <div className="imgs-left-accordion-right__img-section">
                    <span className="imgs-left-accordion-right__img-section-graphic-square"></span>
                    {imageOne?.src.value && (
                        <figure className="imgs-left-accordion-right__img-container imgs-left-accordion-right__img-container--bottom">
                            <img
                                src={imageOne.src.value}
                                alt={imageOne?.altTex?.value ?? ''}
                                className="imgs-left-accordion-right__img"
                                width="386"
                                height="258"
                            />
                        </figure>
                    )}

                    <span className="imgs-left-accordion-right__img-section-graphic-triangle"></span>
                    {imageTwo?.src?.value && (
                        <figure className="imgs-left-accordion-right__img-container imgs-left-accordion-right__img-container--top">
                            <img
                                src={imageTwo.src.value}
                                alt={imageTwo?.altTex?.value ?? ''}
                                className="imgs-left-accordion-right__img"
                                width="258"
                                height="360"
                            />
                        </figure>
                    )}
                </div>

                <div className="imgs-left-accordion-right__accordion">
                    <div className="imgs-left-accordion-right__accordion-container">
                        {heading?.text?.value && (
                            <h2 className="imgs-left-accordion-right__accordion-heading">
                                {heading.text.value}
                            </h2>
                        )}
                        <div className="imgs-left-accordion-right__accordion-sections">
                            {repeatable_section.map((section, idx) => (
                                <div
                                    key={idx}
                                    className="imgs-left-accordion-right__accordion-section"
                                >
                                    <button
                                        className={`imgs-left-accordion-right__accordion-heading-container ${activeIndex === idx ? 'active' : ''}`}
                                        onClick={() => toggleAccordion(idx)}
                                        aria-expanded={activeIndex === idx}
                                        aria-controls={`panel-${idx}`}
                                        id={`accordion-header-${idx}`}
                                    >
                                        {section.heading.value}
                                    </button>
                                    {shouldAnimate ? (
                                        <AnimatePresence initial={false}>
                                            {activeIndex === idx && (
                                                <motion.div
                                                    key={`content-${idx}`}
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={accordionPanelVariants}
                                                    className="imgs-left-accordion-right__accordion-panel imgs-left-accordion-right__accordion-panel--react"
                                                    role="region"
                                                    aria-labelledby={`accordion-header-${idx}`}
                                                    id={`panel-${idx}`}
                                                >
                                                    <div className="imgs-left-accordion-right__accordion-panel-txt imgs-left-accordion-right__accordion-panel-txt--bold">
                                                        {CompileMarkdown(section.content.value)}
                                                    </div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    ) : (
                                        // In test environment, use simple conditional rendering
                                        activeIndex === idx && (
                                            <div
                                                className="imgs-left-accordion-right__accordion-panel"
                                                role="region"
                                                aria-labelledby={`accordion-header-${idx}`}
                                                id={`panel-${idx}`}
                                            >
                                                <div className="imgs-left-accordion-right__accordion-panel-txt imgs-left-accordion-right__accordion-panel-txt--bold">
                                                    {CompileMarkdown(section.content.value)}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ImagesAndAccordion;