import {useState} from "react";
import {Props_Videotext} from './VideoText.props'
import {CompileMarkdown} from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';



const VideoText = (props: Props_Videotext) => {

    const convertIframeUrl = (url: string): string => {
        let newUrl = url;

        // Handle YouTube URLs
        if (url.includes("watch?v=")) {
            newUrl = url.replace("watch?v=", "embed/");
        } else if (url.includes("youtu.be")) {
            newUrl = url.replace("youtu.be/", "youtube.com/embed/");
        }

        // Handle Vimeo URLs
        if (url.includes("vimeo.com")) {
            const match = url.match(/vimeo\.com\/(\d+)/);
            if (match) {
                newUrl = `https://player.vimeo.com/video/${match[1]}`;
            }
        }

        // Add autoplay for all URLs if not already present
        if (!newUrl.includes("autoplay=1")) {
            newUrl = `${newUrl}${newUrl.includes("?") ? "&" : "?"}autoplay=1`;
        }

        return newUrl;
    };
    
    const {heading, repeatable_paragraph, link, playButon, videoThumbnail, orientation, videoSrc} = props
    
    const formattedUrl = convertIframeUrl(videoSrc?.src?.value ? videoSrc.src.value : '')
    const [playing, setPlaying] = useState(false);
    
    return (
        <section className="text-video">
            <div className={`text-video__container text-video__container--${orientation.orientation.value}`}>
                <div className="text-video__left-section">
                    <div className="text-video__info-container">
                        {heading?.text?.value && <h2 className="text-video__heading">{heading.text.value}</h2>}
                        {repeatable_paragraph.map((para, idx) => (
                            <div key={idx} className="text-video__txt">{CompileMarkdown(para.text.value)}</div>
                        ))}
                        {link?.href?.value && <a
                            href={link.href.value}
                            className="text-video__link attrax-button"
                            aria-label={link?.ariaLabel?.value ? link.ariaLabel.value : ''}
                        >{link?.text?.value ? link.text.value : ''}</a>}

                    </div>
                </div>
                <div className="text-video__right-section">
                    <div className="text-video__video-container">
                        {playButon?.ariaLabel?.value && !playing && <button
                            className="text-video__play-button-container"
                            aria-label={playButon.ariaLabel.value}
                            onClick={() => setPlaying(true)}
                        >
                            <div className="text-video__play-btn"></div>
                            <h3 className="text-video__sub-heading">
                                {playButon?.buttonHeading?.value ? playButon.buttonHeading.value : 'Watch Video'}
                            </h3>
                        </button>}
                        {videoThumbnail?.src?.value && <figure className="text-video__thumbnail-container">
                            <img
                                src={videoThumbnail.src.value}
                                alt={videoThumbnail?.alt?.value ? videoThumbnail.alt.value : ''}
                                className="text-video__thumbnail"
                                style={playing ? {display: 'none'} : {display: 'block'}}
                            />
                        </figure>}
                        {playing && (<iframe
                            width="560"
                            height="315"
                            src={formattedUrl}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>)}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoText