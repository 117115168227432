﻿

export function ClickHandlers($: JQueryStatic): void {

    $('.main-site-header__search-section .attrax-search-widget__search-input, .main-site-header__mobile-open-search').on('click', function () {

        $('body').addClass('overlay-open');
        $('body').addClass('nav-open');
        $('.search-overlay').addClass('overlay-open');

    });


    $('.search-overlay__close-btn').on('click', function () {
        $('body').removeClass('overlay-open');
        $('body').removeClass('nav-open');
        $('.search-overlay').removeClass('overlay-open');

    });

}