import {Props_LinkBoxes} from "./LinkBoxes.props";
const LinkBoxes = (props: Props_LinkBoxes) => {
    
    const {heading, repeatable_linkBox} = props;
    
    return (
        <section className="link-boxes">
            {heading?.txt?.value && <h2 className="link-box__heading accessible-heading">Heading</h2>}

            <div className="link-boxes__container">
                {repeatable_linkBox.map((linkBox, index) => (
                    <a className="link-boxes__link-box" href={linkBox?.linkHref?.value} aria-label={linkBox?.linkAriaLabel?.value ? linkBox.linkAriaLabel.value : ''} key={index}>
                        <figure className="link-boxes__img-container">
                            <img
                                src={linkBox.imgSrc.value}
                                alt={linkBox.imgAlt.value ? linkBox.imgAlt.value : ''}
                                className="link-boxes__img"
                                height="290"
                                width="290"
                            />
                        </figure>
                        <div className="link-boxes__info-container">
                            {linkBox?.heading?.value && <h3 className="link-boxes__heading">{linkBox.heading.value}</h3>}

                            {linkBox?.linkText.value && <div className="link-boxes__link attrax-button">
                                {linkBox?.linkText?.value}
                            </div>}

                        </div>
                    </a>
                ))}

            </div>
        </section>
    )
}

export default LinkBoxes