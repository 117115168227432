import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Props_SnippetTabSwitcher } from './TabSwitcher.props';
import { CompileMarkdown } from '../../../../Shared/AttraxTs/React/ReactSnippetMDCompiler';

const SnippetTabSwitcher = (props: Props_SnippetTabSwitcher) => {
    const { heading, repeatable_tab } = props;
    const [activeTab, setActiveTab] = useState(0);
    const [containerOpen, setContainerOpen] = useState(false);

    const snippetID = Math.random().toString(36).substring(2, 10);
    const classHeadingVisibility: string = heading.visibility.value ? `snippet-tab-switcher__heading--${heading.visibility.value}` : '';

    const tabVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 0,
        }
    };
    
    const showContainer = () => {
        setContainerOpen(!containerOpen);
    }
    
    const activeTabHandler = (index: number) => {
        setActiveTab(index);
        showContainer();
    }

    return (
        <section className="snippet-tab-switcher">
            <div className="snippet-tab-switcher__container">
                <h2
                    id={`snippet-tab-switcher-${snippetID}`}
                    className={`snippet-tab-switcher__heading ${classHeadingVisibility}`}
                >
                    {heading?.content?.value}
                </h2>
                <div className="snippet-tab-switcher__container-wrapper">
                    <div
                        aria-labelledby={`snippet-tab-switcher-${snippetID}`}
                        className="snippet-tab-switcher__button-section"
                    >
                        
                            <h3 className="snippet-tab-switcher__active-heading snippet-tab-switcher__active-heading--loaded" onClick={showContainer}>
                                {repeatable_tab[activeTab]?.heading?.value || 'Select a tab'}
                            </h3>
                            <div className={`snippet-tab-switcher__button-container ${containerOpen ? 'snippet-tab-switcher__button-container--open' : ''}`}>
                                {repeatable_tab.map((tab, index) => (
                                    <button
                                        key={`snippet-tab-switcher-${snippetID}-${index}`}
                                        role="tab"
                                        aria-selected={activeTab === index}
                                        aria-controls={`snippet-tab-switcher-${snippetID}-${index}`}
                                        className="snippet-tab-switcher__tabs-button"
                                        onClick={() => activeTabHandler(index)}
                                    >
                                        {tab?.heading?.value}
                                    </button>
                                ))}
                            </div>
                        

                    </div>
                    <div className="snippet-tab-switcher__container-content" style={{position: 'relative' }}>
                        {repeatable_tab.map((tab, index) => (
                            <motion.article
                                key={`snippet-tab-switcher-${snippetID}-${index}`}
                                id={`snippet-tab-switcher-${snippetID}-${index}`}
                                role="tabpanel"
                                aria-hidden={activeTab !== index ? 'true' : 'false'}
                                className="snippet-tab-switcher__tab"
                                initial="initial"
                                animate={activeTab === index ? "animate" : "initial"}
                                variants={tabVariants}
                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                style={{
                                    display: 'block',
                                    visibility: activeTab === index ? 'visible' : 'hidden',
                                    position: activeTab === index ? 'relative' : 'absolute'
                                }}
                            >
                                <h3 className="snippet-tab-switcher__info-heading">
                                    {tab.heading?.value}
                                </h3>
                                <div className="snippet-tab-switcher__tab-container">
                                    {tab.image?.value && (
                                        <img
                                            className="snippet-tab-switcher__tab-image"
                                            src={tab.image.value}
                                            alt={tab.alt?.value ? tab.alt.value : ''}
                                            loading="lazy"
                                            height="300"
                                            width="300"
                                        />
                                    )}
                                    <div className="snippet-tab-switcher__tab-container-content">
                                        {tab.content?.value && (
                                            <div className="snippet-tab-switcher__tab-content">
                                                {CompileMarkdown(tab.content.value)}
                                            </div>
                                        )}
                                        {tab.linkText?.value && tab.url?.value && (
                                            <a href={tab.url.value} className="snippet-tab-switcher__tab-link">
                                                {tab.linkText.value}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </motion.article>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};


export default SnippetTabSwitcher;