import {Props_ImageText} from './ImageText.props';
import {CompileMarkdown} from '@ClientBase/React/ReactSnippetMDCompiler';

const ImgLeftTextRight = (props: Props_ImageText) => {
    
    const { image, heading, paragraph, link, orientation, backgroundColour } = props;
    
    const hasBackground = backgroundColour?.hasBackground?.value ? 'image-text--background' : '';
    
    
    return (
        <section className={`image-text ${hasBackground}`}>
            <div className={`image-text__container image-text__container--${orientation.orientation.value}`}>
                <div className="image-text__img-section">
                    <figure className="image-text__img-container">
                        <img
                            src={image.src.value}
                            alt={image.alt.value}
                            className="image-text__img"
                            height="360"
                            width="400"
                        />
                    </figure>
                </div>
                <div className="image-text__text-section">
                    <div className="image-text__text-container">
                        {heading.text.value && <h2 className="image-text__heading">{heading.text.value}</h2>}
                        <div className={'image-text__text'}>
                            {CompileMarkdown(paragraph.text?.value || '')}
                        </div>
                        <a
                            href={link.href.value}
                            className="image-text__link outline-btn"
                            aria-label={link.ariaLabel.value ? link.ariaLabel.value : ''}
                        >
                            {link.text.value}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ImgLeftTextRight;